import React, { useState } from 'react';
import { shortenURL } from '../services/service.js';
import Loader from '../components/loader.js';
import '../css/home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from "react-toastify";
import '../css/login.css';
import one from '../images/one.svg';
import two from '../images/two.svg';
import three from '../images/three.svg';
import 'react-toastify/dist/ReactToastify.min.css';

const LoginPage = (props) => {

  const [longUrl, setLongUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [showLoader, setLoaderStatus] = useState(false);

  const handleUrlChange = (event) => {
    setLongUrl(event.target.value);
  };

  const isValidUrl = urlString => {
    try { 
      return Boolean(new URL(urlString)); 
    }
    catch(e){ 
      return false; 
    }
}

  const toastMessage = (message, type) =>{
    toast[type](message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!longUrl) {
      toastMessage("Please enter a valid url", "error");
      return;
    }
    // url validation
    if(!isValidUrl(longUrl)) {
      toastMessage("Please enter a valid url", "error");
      return;  
    }
    setLoaderStatus(true);
    const response = await shortenURL(longUrl);
    console.log(response);
    if(response?.errorCode === 500) {
      props.logout_action();
      return;  
    }
    setLoaderStatus(false);
    if (response?.errorCode === 400) {
      toastMessage("Error Creating long url. Please try again.", "error");
      return;
    }
    setShortUrl(response.ShortUrl);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(shortUrl)
      .then(() => {
        toast.success("Copied", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .catch((error) => {
        toast.error("Error copying the url", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
  };

  return (
    <div className="home-container">
          <section className="home-top-section">
            <div className="home-content">
              <p className="home-title">URL Shortener</p>
              <form onSubmit={handleSubmit} >
                <input
                  type="text"
                  placeholder="Enter URL"
                  className="input-field"
                  value={longUrl}
                  onChange={handleUrlChange}
                />
                <button type="submit" className="shorten-btn">
                  Shorten
                </button>
              </form>
              {shortUrl && (
                <div className="result-box">
                  <div className="shortened-url-container">
                    <a href={shortUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="shortened-url">
                      {shortUrl}
                    </a>
                    <button className="copy-btn" onClick={handleCopyClick}>
                      <FontAwesomeIcon icon={faCopy} />
                    </button>
                  </div>
                </div>
              )}
              {showLoader && <Loader />}
              <ToastContainer />
            </div>
          </section>
          <section className="home-bottom-section">
            <div className="home-section">
              <img src={one} alt="what" />
              <h3> Select Your Long URL</h3>
              <p>Begin by choosing the lengthy URL you wish to shorten. This could be any link that you find cumbersome or too long to share effectively on platforms with character limits or for aesthetic reasons.</p>
            </div>
            <div className="home-section">
              <img src={two} alt="why" />
              <h3>Paste the URL into the Text Box</h3>
              <p>Navigate to the text input box on our page and paste your chosen long URL directly into it. This action prepares your URL for the transformation process, setting the stage for a more compact and manageable link.</p>
            </div>
            <div className="home-section">
              <img src={three} alt="about" />
              <h3>Obtain Your Shortened URL for Sharing</h3>
              <p>After the quick processing, your new, shortened URL will be provided. This sleek and succinct link is now ready to be used anywhere you wish, making sharing easier and more visually appealing across various platforms.</p>
            </div>
          </section>
        </div>
  );
};

export default LoginPage;
