import React from 'react';
import '../css/footer.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer className="fixed-footer">
      <div className="footer-content">
        <div>
          Copyright © 2024 Rapid Routes. Intended solely for educational purposes and not for commercial or monetary gain.
        </div>
        <div className="contact-us">
          Email:&nbsp;
          <a href="mailto:support@rapidroutes.net" className="email-link">
            <FontAwesomeIcon icon={faEnvelope} /> support@rapidroutes.net
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
