import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import { signIn, getTokenFromCode } from '../services/auth.js';
import { handleErrorResponse } from '../services/service.js';
import LoginPage from './login.js';
import HomePage from '../components/home.js';
import MyURL from '../components/myUrl.js';
import Header from '../components/header';
import Footer from '../components/footer.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../css/landing.css';

const LandingPage = () => {

  const [codeResponse, setCodeResponse] = useState(null);
  const [profile, setProfile] = useState(null);
  const [showLogin, setShowLogin] = useState(true);

  const login = useGoogleLogin({
    onSuccess: (codeResp) => {
      setCodeResponse(codeResp);
    },
    onError: (error) => {
      handleErrorResponse(error);
      logOut();
    },
    flow: "auth-code"
  });

  useEffect(
    () => {
      if (!codeResponse) {
        return;
      }
      setShowLogin(false);
      getTokenFromCode(codeResponse.code).then((response) => {
        // if response is empty or has error code, force the login
        if(!response || response.errorCode) {
          logOut();
          return;
        }
        setProfile(response);
      });
    },
    [codeResponse]
  );

  useEffect(
    () => {
      if (profile) {
        return;
      }
      signIn().then((response) => {
        // if response is empty or has error code, force the login
        if (!response || response.errorCode) {
          logOut();
          return;
        }
        setProfile(response);
      });
    },
    []
  );

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    Cookies.remove("ruid", {path: "/", domain: ".rapidroutes.net"});  
    setProfile(null);
    setShowLogin(true);
  };

  return (
    <div className="app-container">
      {profile ?
        (
          <Router>
            <Header profile={profile} logout_action={logOut}/>
            <div className="content-container">
              <Routes>
                <Route exact path="/" element={<HomePage profile={profile} logout_action={logOut}/>} />
                <Route path="/home" element={<HomePage profile={profile} logout_action={logOut}/>} />
                <Route path="/my-urls" element={<MyURL logout_action={logOut}/>} />
              </Routes>
            </div>
            <Footer/>
          </Router>
        ) :
        (<LoginPage login_action={login} show_login={showLogin} />)
      }
      <Footer/>
    </div>
  );
}

export default LandingPage;
