import axios from 'axios';
import { handleErrorResponse } from './service.js';
import Cookies from 'js-cookie';

const formatUserResponse = (response) => {
    return {
        name: response.name || (`${response.FirstName} ${response.LastName}`),
        picture: response.picture || response.Picture,
        email: response.email || response.Email
    }
}

export const getToken = () => {
    const uid = Cookies.get("ruid");
    if(!uid) {
       throw new Error({errorCode : 500, stack:'Cookies for token not found'});
    }
    return uid;
}

const getUserDetails = async (uid) => {
    try {
        // check if user session is present
        const userInfo = window.sessionStorage.getItem("userinfo");
        if (userInfo) {
            return JSON.parse(userInfo);
        } 
        const apiResponse = await axios.get(process.env.REACT_APP_ENV_TOKEN_URL, {
            headers: {
                'x-api-token': uid
            },
            withCredentials: true
        });
        const response = formatUserResponse(apiResponse.data);
        window.sessionStorage.setItem("userinfo", JSON.stringify(response));
        return response;
    } catch (error) {
        throw error;
    } 
}

export const getTokenFromCode = async (code) => {
    try {
        const apiResponse = await axios.post(process.env.REACT_APP_ENV_TOKEN_URL, {}, {
            headers: {
                'x-api-token': code
            },
            withCredentials: true
        });
        return formatUserResponse(apiResponse.data);
    } catch (error) {
        return handleErrorResponse({errorCode:500, message:'Get User Details Exception', stack: error.stack});
    } 
};

export const signIn = async () => {
    try {
        const uid = getToken();
        const response = await getUserDetails(uid);
        return response;
    } catch(error) {
        // force the landing page to show login page
        return handleErrorResponse({errorCode : 500, message:"Sign in error", stack: error.stack});
    }
};