import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons';
import '../css/header.css';
import headerLogo from '../images/logo.png';

const Header = (props) => {

    const [showMenu, setShowMenu] = useState(false);
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const toggleProfileDropdown = () => {
        setShowProfileDropdown(!showProfileDropdown);
    };

    const handleLogout = () => {
        props.logout_action();
    };

    const userIconUrl = props.profile.picture;

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowProfileDropdown(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <header className="fixed-header">
            <div className="branding">
                <img src={headerLogo} height="20" width="125" alt="logo"></img>   
            </div>
            <nav>
                <ul className={showMenu ? 'show-link' : 'hide-link'}>
                    <li onClick={toggleMenu}><Link to="/home">Home</Link></li>
                    <li onClick={toggleMenu}><Link to="/my-urls">My Urls</Link></li>
                </ul>
            </nav>
            <div class="menu-parent">
            <div className="menu-icon" onClick={toggleMenu}>
                <FontAwesomeIcon icon={faBars} /> {/* Assuming you import faBars */}
            </div>

            <div className="profile-icon" ref={dropdownRef}>
    {showProfileDropdown && (
        <div className="profile-dropdown">
            <div className="user-info">
                <div className="username">
                    <strong>{props.profile.name}</strong>
                </div>
                <div className="user-email">{props.profile.email}</div>
            </div>
            <div className="logout" onClick={handleLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>Logout</span>
            </div>
        </div>
    )}
    <div className="user-icon" onClick={toggleProfileDropdown} style={{ backgroundImage: `url(${userIconUrl})` }}></div>
</div>
            </div>
        </header>
    );
};

export default Header;