import axios from 'axios';
import {getToken} from './auth.js';

export const handleErrorResponse = (error) => {
    // handle error logging
    if(error.errorCode) {
        return error;
    }
    return {errorCode:400, message:"Exception in the service call", stack : error.stack};
};

export const shortenURL = async (longUrl) => {
    try {
        const apiResponse = await axios.post(process.env.REACT_APP_ENV_SHORTEN_URL, {"longUrl" : longUrl}, {
            headers: {
                'x-api-token': getToken()
            },
            withCredentials: true
        });
        return apiResponse.data;
    } catch(error) {
        return handleErrorResponse(error);
    }
}

export const getMyUrls = async () => {
    try {
        const apiResponse = await axios.get(process.env.REACT_APP_ENV_SHORTEN_URL, {
            headers: {
                'x-api-token': getToken()
            },
            withCredentials: true
        });
        return apiResponse.data;
    } catch(error) {
        return handleErrorResponse(error);
    }
}