import React, { useState, useEffect } from 'react';
import { getMyUrls } from '../services/service.js';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css"
import Loader from '../components/loader.js';
import '../css/myUrl.css';
import { ToastContainer, toast } from "react-toastify";

const DynamicTable = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 20, 40, 60, 100, 200];

  const toastMessage = (message, type) =>{
    toast[type](message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await getMyUrls();
        if(result?.errorCode === 500) {
          props.logout_action();
          return;  
        }
        if (result?.errorCode === 400) {
          toastMessage("Error loading data. Please try again.", "error");
          return;
        }
        setData(result);
      } catch (error) {
        toastMessage("Encountered error. Please refresh the page", "error");
      } finally {
        setIsLoading(false);
      }

    };
    fetchData();
  }, []);

  const cellLinkRenderer = (params) => {
    return <a style={{color:'#0000EE'}} href={params.value} target="_blank" rel="noreferrer">{params.value}</a>;
  }

  const columns = [
    { field: 'Id', headerName: 'ID', filter: true, checkboxSelection: true, headerCheckboxSelection: true },
    { field: 'ShortUrl', headerName: 'Short Url', filter: true, cellRenderer: cellLinkRenderer },
    { field: 'LongUrl', headerName: 'Long Url', filter: true, flex: 2, cellRenderer: cellLinkRenderer},
  ];

  return (
    <div className="ag-theme-alpine" style={{ height: 600 }}>
      {isLoading && (<Loader />)}
      <AgGridReact rowData={data} columnDefs={columns} rowSelection="multiple"
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        paginationPageSizeSelector={paginationPageSizeSelector}
      />
      <ToastContainer />
    </div>
  );
};

export default DynamicTable;
