import React from 'react';
import Loader from '../components/loader';
import '../css/login.css';
import what from '../images/what.svg';
import why from '../images/why.svg';
import about from '../images/about.svg';

const LoginPage = (props) => {
 
  const handleLogin = () => {
    props.login_action();
  };

  return (
    <div className="login-container">
      {props.show_login ? (
        <div>
          <section className="top-section">
            <div className="login-content">
              <p className="header-titile">Rapid Routes</p>
              <p className="description">
                your go to url shortener
              </p>
              <button className="signin-btn" onClick={handleLogin}>Sign in with Google</button>
            </div>
          </section>
          <section className="bottom-section">
            <div className="section">
              <img src={what} alt="what"/>
              <h3>Whats is Rapid Route ?</h3>
              <p>Rapid Routes is a URL shortener service designed to streamline web addresses, making them shorter and more manageable for effortless sharing and improved user accessibility online.</p>
            </div>
            <div className="section">
              <img src={why} alt="why"/>
              <h3>Why URL Shortener?</h3>
              <p>URL shorteners are essential for condensing lengthy web addresses into concise links, facilitating easier sharing and enhancing user experience across digital platforms.</p>
            </div>
            <div className="section">
              <img src={about} alt="about"/>
              <h3>About us</h3>
              <p>An enthusiast, eager to learn and dive into tech, grows skills and makes things happen. This was created with the sole purpose of learning.</p>
            </div>
          </section>
        </div>
      ) : (
        <Loader />
      )}
      </div>
  );
};

export default LoginPage;
